import React, { Component } from "react"
import PropTypes from "prop-types"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Card, Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"

import "swiper/swiper.less"
import "swiper/components/navigation/navigation.less"
import "swiper/components/pagination/pagination.less"
import "./SliderTestimoni.less"

const { Meta } = Card

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

export class SliderTestimoni extends Component {
  render() {
    return (
      <Swiper
        spaceBetween={20}
        className="swiper-testimoni"
        navigation={{
          nextEl: ".next",
          prevEl: ".prev",
        }}
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 1,
            // spaceBetween: 20
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2,
            // spaceBetween: 20
          },
        }}
      >
        <div className="swiper-testimoni-navigation">
          <div className="prev">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.3332 12.8332H9.13484L15.6565 6.3115L13.9998 4.6665L4.6665 13.9998L13.9998 23.3332L15.6448 21.6882L9.13484 15.1665H23.3332V12.8332Z"
                fill="#FF6900"
              />
            </svg>
          </div>
          <div className="next">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.64902 15.0118L18.8454 15.2472L12.2165 21.6598L13.8457 23.3321L23.3325 14.1548L14.1551 4.66799L12.4831 6.28549L18.8841 12.9142L4.6877 12.6788L4.64902 15.0118Z"
                fill="#FF6900"
              />
            </svg>
          </div>
        </div>
        {this.props.sliderContent.map(function (item, i) {
          return (
            <SwiperSlide key={i}>
              <Card className="card-swiper-testimoni">
                <Meta
                  avatar={
                    <Avatar
                      src={item.photo}
                      icon={<UserOutlined />}
                      size={72}
                    />
                  }
                  title={item.name}
                  description={item.job_position}
                />
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              </Card>
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  }
}

SliderTestimoni.propTypes = {
  sliderContent: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    jabatan: PropTypes.string,
    testimoni: PropTypes.string,
  }),
}

export default SliderTestimoni
